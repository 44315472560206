import { Image } from "antd"
import React from "react"
import Page from "../components/page"
import Paragraph from "../components/paragraph"

export default function About({ labels, ...props }) {
  return (
    <Page title={labels.about} {...props} labels={labels}>
      <div className="paragraphs-page about-page">
        <div>
          <Paragraph
            title={labels.aboutUppercased}
            version="primary"
            orientation="horizontal"
            textOrientation="left"
          />
          <Paragraph
            text={[
              labels.aboutLunartech1,
              labels.aboutLunartech2,
              labels.aboutLunartech3,
              labels.aboutLunartech31,
              labels.aboutLunartech32,
              labels.aboutLunartech4
            ]}
            version="secondary"
            orientation="horizontal"
            textOrientation="left"
            textDivided={true}
          />
        </div>
        <div className="about-picture">
          <Image src="/about_picture.jpg" preview={false} alt="about" />
        </div>
      </div>
    </Page>
  )
}
